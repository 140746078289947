<template>
  <!-- eslint-disable vue/no-v-html -->
  <div>
    <template v-if="!loadingLandingPage">
      <div class="container le-container mt-2">
        <main role="main" class="flex-shrink-0 mb-5">
          <div class="row">
            <div class="col mb-5">
              <h1 class="text-center">{{dataLandingPage.title}}</h1>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 px-4">
              <div v-if="dataLandingPage.video_url" class="video-area mb-2">
                <div style="width: 100%;">
                  <div style="position: relative; width: 100%; overflow: hidden; padding-top: 56.25%;">
                    <iframe ref="videoPlayer" style="position: absolute; top: 0; left: 0; right: 0; width: 100%; height: 100%;" width="560" height="315" :src="videoEmbedUrl" title="Video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
                </div>
              </div>
              <div v-if="business && dataLandingPage.show_review" class="business-section font-size-18 mb-2 p-2">
                <h5 class="font-size-24 mb-0 font-weight-600">{{business.business_name}}</h5>
                <div class="rating-row d-flex align-items-center">
                  <span class="rating mr-2">{{business.rating.toString()}}</span>
                  <div class="star-container-gold mr-2">
                    <div class="rating-upper" :style="`width: ${starWidth}%`">
                      <span>★</span> <span>★</span> <span>★</span> <span>★</span> <span>★</span>
                    </div>
                    <div class="rating-lower">
                      <span>★</span> <span>★</span> <span>★</span> <span>★</span> <span>★</span>
                    </div>
                  </div>
                  <div class="review-count mr-1">
                    ({{business.review_count}})
                  </div>
                  <span v-if="business.primary_category !== ''" class="category">
                    <span class="px-1">·</span>
                    {{business.primary_category}}
                  </span>
                </div>
                <div class="address-row d-flex align-items-center">
                  <span class="address mr-1">
                    {{business.address}}
                  </span>
                  <span v-if="business.business_phone !== ''" class="phone">
                    <span class="px-1">·</span>
                    {{business.pretty_phone}}
                  </span>
                </div>
                <div v-if="business.single_best_review && dataLandingPage.show_review" class="row mt-2">
                  <div class="col-auto text-center">
                    <img v-if="business.single_best_review.author_image" :src="business.single_best_review.author_image" style="max-width: 30px;"/>
                    <svg v-else width="30px" viewBox="0 0 128 128" role="img" aria-label="avatar" style="background:#a9c2f6; border-radius:50%">
                      <path fill="#4575e2" d="M103,102.1388 C93.094,111.92 79.3504,118 64.1638,118 C48.8056,118 34.9294,111.768 25,101.7892 L25,95.2 C25,86.8096 31.981,80 40.6,80 L87.4,80 C96.019,80 103,86.8096 103,95.2 L103,102.1388 Z"></path>
                      <path fill="#4575e2" d="M63.9961647,24 C51.2938136,24 41,34.2938136 41,46.9961647 C41,59.7061864 51.2938136,70 63.9961647,70 C76.6985159,70 87,59.7061864 87,46.9961647 C87,34.2938136 76.6985159,24 63.9961647,24"></path>
                    </svg>
                  </div>
                  <div class="col pl-0">
                    {{business.single_best_review.review_text}}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 px-4 mx-auto text-center">
              <h2 class="font-size-22 mb-4 font-weight-bold">{{dataLandingPage.form_header}}</h2>
              <ValidationObserver
                  v-slot="{ handleSubmit, invalid, touched }"
                  ref="landingPageForm"
              >
                <form
                    class="lead-form"
                    @submit.prevent="handleSubmit(submitForm)"
                >
                  <TextInput v-model="form.name" placeholder="Full Name" name="Name" rules="required" />
                  <TextInput v-model="form.email" placeholder="Email" name="Email"  rules="required" />
                  <TextInput v-model="form.phone" placeholder="Phone" name="Phone" rules="required" />
                  <b-form-group class="mt-3">
                    <b-button
                        variant="primary"
                        class="landing-pg-btn font-size-20 font-weight-bold"
                        :disabled="formWorking || (invalid && touched)"
                        type="submit"
                    >
                      <b-spinner v-if="formWorking" small />
                      <span v-else>{{dataLandingPage.button_text}}</span>
                    </b-button>
                  </b-form-group>
                </form>
              </ValidationObserver>
              <div v-if="submissionMessage !== ''" class="text-success font-weight-bold">{{submissionMessage}}</div>
            </div>

          </div>
          <div class="row mt-5">
            <div v-if="dataLandingPage.address" class="col text-center font-size-13 mt-4">
              <span>Address: {{ dataLandingPage.address }}</span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col text-center font-size-16">
              <a class="mr-4 font-weight-bold" @click="showPrivacyModal = true">Privacy</a>
              <a class="font-weight-bold" @click="showTermsModal = true">Terms</a>
            </div>
          </div>
        </main>
      </div>
      <b-modal v-model="showPrivacyModal" title="Privacy Policy" :ok-only="true" size="lg" ok-variant="dark">
        <div v-if="dataLandingPage.agency.settings" v-html="dataLandingPage.agency.settings.privacy_policy"></div>
      </b-modal>
      <b-modal v-model="showTermsModal" title="Terms of Service" :ok-only="true" size="lg" ok-variant="dark">
        <div v-if="dataLandingPage.agency.settings" v-html="dataLandingPage.agency.settings.terms_of_service"></div>
      </b-modal>
    </template>
    <div v-else-if="status === 404" class="d-flex align-items-center justify-content-center vh-100 bg-primary m-0 p-0 error-div">
      <h1 class="display-4 fw-bold text-white text-center">
        Business Not Found
      </h1>
    </div>
    <div v-else class="text-center mt-4">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>

<script>
import urlParser from "js-video-url-parser";
export default {
  components:{
  },
  props:{
    landingPage: {
      type: Object,
      default:null
    },
    showSites:{
      type: Boolean,
      default:false
    },
    landingBusiness:{
      type:Object,
      default:null
    }
  },
  data() {
    return {
      business:this.landingBusiness,
      loadingLandingPage: true,
      dataLandingPage:this.landingPage,
      status:200,
      business_id:null,
      employee_id:null,
      showPrivacyModal:false,
      showTermsModal:false,
      formWorking:false,
      submissionMessage:'',
      form:{
        agency_id:null,
        name:'',
        email:'',
        phone:'',
        page_id:this.$route.params.landing_page,
        lead_id:this.$route.params.unique_id
      }
    }
  },
  computed: {
    starWidth(){
      return (this.business.rating / 5) * 100
    },
    reviewLink(){
      const placeId = this.dataBusiness.place_id;
      return 'https://search.google.com/local/writereview?placeid=' + placeId;
    },
    titleComputed(){
      return "Your Special Offer";
    },
    videoEmbedUrl(){
      try{
        const parser = urlParser.parse(this.dataLandingPage.video_url);
        return urlParser.create({
          videoInfo:parser,
          format:'embed'
        })
        // return parser[parser.provider].getEmbedUrl(this.dataLandingPage.video_url)
      }catch (e){
        return this.dataLandingPage.video_url;
      }
    }
  },
  watch: {
  },
  metaInfo () {
    return {
      title: this.titleComputed,
      meta: [
        { name: 'og:title', content: this.titleComputed },
      ],

    }
  },

  mounted() {
    document.body.classList.add('le-landing-page');
    if(this.dataLandingPage === null) {
      this.markVisited();
      this.getPageData();
    }else{
      this.dataLandingPage = this.landingPage;
      this.loadingLandingPage = false;
    }
  },
  beforeDestroy() {
    document.body.classList.remove('le-landing-page');
  },
  methods: {
    markVisited(){
      this.$store
          .dispatch('leads/markVisited', {landingPage:this.$route.params.landing_page, uniqueId:this.$route.params.unique_id})
          .then((res) => {

          })
          .catch((error) => {
            if(error.response.status){
              this.status = error.response.status;
              document.body.classList.add('gate-404');
              this.loadingLandingPage = false;
            }
          })
    },
    getPageData() {
      this.loadingLandingPage = true;
      this.$store
          .dispatch('leads/getLeadAndPageData', {landingPage:this.$route.params.landing_page, uniqueId:this.$route.params.unique_id, is_admin:true})
          .then((res) => {
            this.dataLandingPage = res.data;
            this.business = res.business;
            this.loadingLandingPage = false;
            this.form.agency_id = this.dataLandingPage.agency.id;
          })
          .catch((error) => {
            if(error.response.status){
              this.status = error.response.status;
              document.body.classList.add('gate-404');
            }
            this.loadingLandingPage = false
          })
    },
    submitForm(){
      this.formWorking = true;
      this.submissionMessage = '';
      this.$store
          .dispatch('leads/submitLead', this.form)
          .then((res) => {
            if(res.success){
              if(this.dataLandingPage.show_redirect && this.dataLandingPage.redirect_url !== ''){
                  window.location.href = this.dataLandingPage.redirect_url;
              }else {
                this.submissionMessage = res.message;
                this.form.name = '';
                this.form.email = '';
                this.form.phone = '';
                this.$refs.landingPageForm.reset();
              }
            }
            this.formWorking = false;
          })
          .catch((error) => {
            console.log(error);
            this.formWorking = false
          })
    }
  },
}
</script>
<style>
@import "https://fonts.googleapis.com/css2?family=Raleway&display=swap";
</style>
<style lang="scss" scoped>
.container{
  max-width: 1120px;
  padding:20px;
}
.review-text{

}
.landing-pg-btn{
  width: 100%;
  max-width: 335px;
  height: 50px;
  background: #0374c7;
}
.star-container-gold{
  display: inline-block;
  unicode-bidi: bidi-override;
  color: #888888;
  margin-top: -5px;
  font-size: 20px !important;
  span{
    font-size: 20px !important;
  }
  height: 25px;
  width: auto;
  position: relative;
  padding: 0;
  .rating-upper {
    color: #fbbc07;
    padding: 0;
    position: absolute;
    z-index: 1;
    display: flex;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  .rating-lower {
    padding: 0;
    display: flex;
    z-index: 0;
  }
}
</style>
<style lang="scss">
body.gate-404{
  padding-bottom: 0;
}
body.le-landing-page{
  background: #fff;
  .video-area{
    box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.3);
  }
}
</style>
